import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/tlarson/dev/home/familyhistories/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "index-to-add-meaning"
    }}>{`Index to Add Meaning!`}</h3>
    <p>{`Beyond just sharing files and stories, it's very useful to "index" your files and identify people to link all these to other documentation; this makes it easier to search for people and correlate them.  By modifying your HTML files according to the standards below, you make it possible for programs to automatically link your information with other documents.  This is much like what the `}<a parentName="p" {...{
        "href": "https://familysearch.org/indexing/"
      }}>{`Family Search indexing project`}</a>{` is doing, though they have tools that make it even easier to add the semantics.  (I'd love to create/find open-source versions of those tools as well.)`}</p>
    <p>{`Note that the methods here are specifically for files in HTML format.  If you're not accustomed to HTML documents, try `}<a parentName="p" {...{
        "href": "http://www.ironspider.ca/basic_html/howtomakeawebpage.htm"
      }}>{`an online tutorial`}</a>{` or ask a friend for a quick explanation.`}</p>
    <h3>Basic Links</h3>
    <p>
Wherever there is a person's name (or other useful data) a your document, add tags that link it to other references for that person; then programs can detect and point people to those references.
    </p>
    <p>{`Here is sample text from the start of `}<a parentName="p" {...{
        "href": "http://ec2-34-228-224-89.compute-1.amazonaws.com/tolman-histories/Aaron_Bracken.htm"
      }}>{`this document`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`Aaron Bracken was born January 26, 1844, at Hartland, Niagara, New York.  He was the son of Joshua and Hannah Bell Bracken.
`}</code></pre>
    <p>{`We surround the name of Aaron Bracken with a "span" tag, and also add some "itemprop" definitions:`}</p>
    <pre><code parentName="pre" {...{}}>{`<span itemscope itemtype="http://historical-data.org/Person">
<meta itemprop="url" content="https://familysearch.org/tree/#view=ancestor&person=KWVP-7PM" />
<meta itemprop="url" content="http://thomas.tolmanfamily.org/genealogy-pages/ppl/e/5/c4fe2696ab1711b005e.html" />
Aaron Bracken
</span>
was born January 26, 1844, at Hartland, Niagara, New York.  He was the son of Joshua and Hannah Bell Bracken.
`}</code></pre>
    <p>{`"So what?"  Well, here's what: now you can use semantic tools to start browsing around these files.`}</p>
    <p>{`For example, here's a little bookmarklet that will guide you to those linked sites:`}</p>
    <blockquote>
      <a href='javascript:  var scriptElem=document.createElement("script");  scriptElem.setAttribute("type","text/javascript");  scriptElem.setAttribute("src","http://familyhistories.info/static/tools/activate-links-bookmarklet/setup.js");  document.body.appendChild(scriptElem);  void(0);'>"Activate Ancestry Links" Bookmarklet</a>
    </blockquote>
    <p>{`Drag that bookmarklet into your bookmarks. (If that's not obvious, see more `}<a parentName="p" {...{
        "href": "https://support.mozilla.org/en-US/kb/bookmarklets-perform-common-web-page-tasks"
      }}>{`instructions here`}</a>{`.) Then go `}<a parentName="p" {...{
        "href": "http://ec2-34-228-224-89.compute-1.amazonaws.com/tolman-histories/Aaron_Bracken.htm"
      }}>{`here to see a sample file`}</a>{` and click on that bookmarklet to activate the links that are hidden within it.  To see this in action, watch `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=VAfdGHnTFIQ"
      }}>{`this video`}</a>{`.`}</p>
    <p>
To see the whole process of adding semantic tags, watch <a href="https://www.youtube.com/watch?v=QbFJ7CixEVk">this video</a>.
    </p>
    <p>For those of you passionate about local apps, you can run this locally without a network connection: <a href="/static/tools/activate-links-bookmarklet-local.tgz">download all the files</a> and edit the setup.js file to point to those local files, and then you can edit the bookmarklet to point to that setup.js file.</p>
    <h3>Advanced Concepts</h3>
    <p>The "url" itemprops are a very basic form of linking, pointing directly to some web
location, which I don't think is a good long-term solution.  The right way to do this is
to reference these shared datum with URIs or XRIs instead.  The same Aaron Bracken can be
located in multiple locations; we know Family Search has some info, so if we want to see
the Family Search version then we should be able to simply store his ID (KWVP-7PM) and the
owner of that ID (FamilySearch.org), and then the tools can look him up in a browser with
their URL scheme (eg. appending their ID to
"https://familysearch.org/tree/#view=ancestor&person=").
    </p>
    <p>My current problem is that I don't know of the perfect scheme to store these IDs for
arbitrary systems.  Here are a few possibilities:
    </p>
    <ul>
      <li parentName="ul">{`HTML element of "a" or "link"`}</li>
    </ul>
    <p>{`(Not ideal because you can only attach one link to your data; also, these are for URLs, which are direct HTTP locations which can change.)`}</p>
    <pre><code parentName="pre" {...{}}>{`<a href="https://familysearch.org/tree/#view=ancestor&person=KWVP-7PM">Aaron</a>
`}</code></pre>
    <ul>
      <li parentName="ul">{`itemprop of 'url', content with location (as used above)`}</li>
    </ul>
    <p>{`(Not ideal because this is just like a URL... it doesn't track the specific ID or other info that may be important for an API.)`}</p>
    <pre><code parentName="pre" {...{}}>{`<meta itemprop="url" content="KWVP-7PM" itemprop="https://familysearch.org/tree/#view=ancestor&person=KWVP-7PM" />
`}</code></pre>
    <ul>
      <li parentName="ul">{`itemprop with namespace URL, content with ID`}</li>
    </ul>
    <p>{`(Not ideal because itemprop values are typically something short and well-known, eg. "externalId")`}</p>
    <pre><code parentName="pre" {...{}}>{`<meta itemprop="https://familysearch.org/tree/ancestor" content="KWVP-7PM" />
`}</code></pre>
    <ul>
      <li parentName="ul">{`itemprop of "externalId" or "externalUrn" and content of combination namespace & ID`}</li>
    </ul>
    <p>{`(Not ideal because the namespace & ID are smushed together.)`}</p>
    <pre><code parentName="pre" {...{}}>{`<meta itemprop="externalId" content="https://familysearch.org/tree/ancestor&id=KWVP-7PM" />
`}</code></pre>
    <ul>
      <li parentName="ul">{`itemprop of "externalId" or "externalUrn" and content of ID and some new attribute of namespace`}</li>
    </ul>
    <p>{`(Not ideal because "namespace" isn't a generally recognized attribute.)`}</p>
    <pre><code parentName="pre" {...{}}>{`<meta itemprop="externalId" namespace="https://familysearch.org/tree/ancestor" content="KWVP-7PM" />
`}</code></pre>
    <ul>
      <li parentName="ul">{`HTML `}<strong>{`object`}</strong>{` with embedded type and data elements (and maybe param elements?)`}</li>
    </ul>
    <p>{`(Not ideal because it's generally recognized for other content and not semantic data, and it's more complex.)`}</p>
    <ul>
      <li parentName="ul">{`XRIs`}</li>
    </ul>
    <p>{`(May be ideal, but I just don't know enough about them... and neither do most people.)`}</p>
    <p>My next problem after that: how to store these IDs for reference in our local, private
copies of the data.  A good start might be the <a href="http://tools.ietf.org/html/rfc3986#section-5.2">relative references in URIs</a>;
the GEDCOM X file format has <a href="https://github.com/FamilySearch/gedcomx/blob/master/specifications/file-format-specification.md#53-relative-references">a
blurb on that kind of usage</a>, albeit inside a self-contained bundle.</p>
    <p>Any ideas or other pointers welcome.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      